<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("managecompany") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->

        <div class="row d-flex justify-content-center">
          <div class="col-sm-10 mb-4">
            <label
              for="secondaryProductCode"
              class="form-label col-sm-10 required"
              >{{ $t("logo_img") }}
            </label>
            <img
              :src="form.logo_img ? form.logo_img : 'media/avatars/blank.png'"
              class="m-4"
              style="width: 100px; height: 100px"
            />
            <div class="col-sm-10">
              <input
                type="file"
                accept="image/*"
                id="file"
                ref="logo_img"
                v-on:change="submitfileMain('logo_img')"
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("company_code") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.company_code"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("company_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.company_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("full_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.full_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("address") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.address"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("moo") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.moo"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("subdistrict") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.sub_district"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("district") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.district"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("province") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.province"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("postcode") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.postcode"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("tax_id") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.tax_id"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_no") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_no"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-5 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-10 mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >{{ $t("bank_account_name") }}</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.bank_account_name"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
            </div>
          </div>
          <!-- <div class="col-sm-5 mb-4">
            <label for="secondaryProductCode" class="form-label col-sm-10">{{
              $t("payment_img")
            }}</label>
            <div class="col-sm-10">
              <input
                v-model="form.payment_img"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              />
            </div>
          </div> -->
          <!-- //วาป -->
          <div class="col-sm-10 mb-4">
            <label
              for="secondaryProductCode"
              class="form-label col-sm-5 required"
              >{{ $t("payment_img") }}
            </label>
            <div class="col-sm-10">
              <img
                :src="
                  form.payment_img
                    ? form.payment_img
                    : 'media/avatars/blank.png'
                "
                class="m-4"
                style="width: 200px; height: 200px"
              />
            </div>
            <div class="col-sm-10">
              <input
                type="file"
                accept="image/*"
                id="file"
                ref="payment_img"
                v-on:change="submitfileMain('payment_img')"
              />
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10 mb-4">
            <h3>ระบุรูปแบบเลขที่เอกสาร</h3>
          </label>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>ใบรับสินค้าเข้าคลัง</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.adjust_stock"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.adjust_stock_number_running123"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบกำกับ CASHVAN</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_cashvan"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_cashvan_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบกำกับ PRE-ORDER</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_preorder"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_preorder_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>order number CASHVAN</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_cashvan"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_cashvan_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>order number PRE-ORDER</span>
          </div>
          <!-- ///// -->
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_preoder"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_preoder_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอโอนสินค้าระหว่างคลังถึงคลัง</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_between_wherehouse_number"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_between_wherehouse_number_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอโอนสินค้าระหว่างคลังถึงแวน</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_wherehouse_to_van_number"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_wherehouse_to_van_number_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอโอนสินค้าระหว่างแวน</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_between_van_number"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_between_van_number_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอปรับสต็อคสินค้าในคลัง</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.adjust_stock_number"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.adjust_stock_number_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบลดหนี้</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.due_number"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.due_number_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>
        </div>

        <!-- <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกบริษัท</label
            >
            <select
              v-model="form.companyId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :disabled="form.role == 'SUPER_ADMIN' ? false : true"
              required
            >
              <option v-for="item in companyItems" :key="item" :value="item.id">
                {{ item.company_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกสาขา</label
            >
            <select
              v-model="form.branchId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :required="form.role == 'SUPER_ADMIN' ? false : true"
            >
              <option v-for="item in branchItems" :key="item" :value="item.id">
                {{ item.code }} - {{ item.short_name }} - {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือก Warehouse</label
            >
            <select
              v-model="form.warehouseId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              :required="form.role == 'SUPER_ADMIN' ? false : true"
            >
              <option
                v-for="item in warehouseItems"
                :key="item"
                :value="item.id"
              >
                {{ item.warehouse_code }} - {{ item.warehouse_short_name }} -
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import axios from "../instance";

export default {
  setup() {
    document.title = "J&N | เพิ่มประเภทสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      name: "",
      adjust_stock: "",
      transaction_invoice_cashvan: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    typeProduct: [],
    companyItems: [],
    branchItems: [],
    warehouseItems: [],
    roleItems: [
      { code: "SUPER_ADMIN", name: "SUPER_ADMIN" },
      { code: "ADMIN", name: "ADMIN" },
      { code: "EMP_1", name: "พนักงาน1" },
      { code: "EMP_2", name: "พนักงาน2" },
      { code: "EMP_3", name: "พนักงาน3" },
    ],
  }),

  async mounted() {
    // this.getMainProduct();
    await this.getOne();

    await this.api1();
    await this.api2();
    await this.api3();
    await this.api4();
    await this.api5();
    await this.api6();
    await this.api7();
    await this.api8();
    await this.api9();
    await this.api10();
  },

  methods: {
    async api1() {
      let responseData = await userApi.company.getRunning(
        this.form.adjust_stock
      );
      this.form.adjust_stock_number_running123 =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api2() {
      let responseData = await userApi.company.getRunning(
        this.form.transaction_invoice_cashvan
      );
      this.form.transaction_invoice_cashvan_running = responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api3() {
      let responseData = await userApi.company.getRunning(
        this.form.transaction_invoice_preorder
      );
      this.form.transaction_invoice_preorder_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api4() {
      let responseData = await userApi.company.getRunning(
        this.form.order_number_cashvan
      );
      this.form.order_number_cashvan_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api5() {
      let responseData = await userApi.company.getRunning(
        this.form.order_number_preoder
      );
      this.form.order_number_preoder_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api6() {
      let responseData = await userApi.company.getRunning(
        this.form.transfer_between_wherehouse_number
      );
      this.form.transfer_between_wherehouse_number_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api7() {
      let responseData = await userApi.company.getRunning(
        this.form.transfer_wherehouse_to_van_number
      );
      this.form.transfer_wherehouse_to_van_number_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api8() {
      let responseData = await userApi.company.getRunning(
        this.form.transfer_between_van_number
      );
      this.form.transfer_between_van_number_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api9() {
      let responseData = await userApi.company.getRunning(
        this.form.adjust_stock_number
      );
      this.form.adjust_stock_number_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },
    async api10() {
      let responseData = await userApi.company.getRunning(
        this.form.due_number
      );
      this.form.due_number_running =
        responseData.data[0]
        ? "67" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6700000";
    },

    async submitfileMain(questionNo) {
      console.log("questionNo", questionNo);
      let responseData = [];
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");

        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        console.log("auth", auth);
        // const response =  await axios.api.post("/files/formData", formData, auth);

        responseData = await userApi.company.upload(formData, auth);

        if (questionNo == "logo_img") {
          this.form.logo_img = responseData.data.path;
        } else if (questionNo == "payment_img") {
          this.form.payment_img = responseData.data.path;
        }
      }
    },
    async getOne() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await userApi.company.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.form = responseData.data;
      }
      this.loading = false;
    },
    // async getAllBranch(companyId) {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.branch.getAll(companyId);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.branchItems = responseData.data;
    //   }
    //   this.loading = false;
    // },
    // async getAllWarehouse(branchId) {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.warehouse.getAll(branchId);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.warehouseItems = responseData.data;

    //     this.loading = false;
    //   } else {
    //     this.loading = false;
    //     Swal.fire({
    //       icon: "error",
    //       title: "เกิดข้อผิดพลาด",
    //       text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
    //     });
    //   }
    // },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
        };

        createResponse = await userApi.company.update(
          this.$route.query.id,
          this.form
        );
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `${this.$t("editsuccess")} `,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/config/company/index",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
      // console.log('submit');
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.company_code
        ? (this.isValid = false)
        : !this.form.company_name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/company/index",
        query: {},
      });
    },
  },
};
</script>
